<template>
    <v-container fluid class="mx-0 px-3">
        <div>
            <h2>Dashboard View</h2>
        </div>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});
export default {
    components: {},
    computed: {
        ...mapState("usersMgmt", ["view"]),
    },
    data: () => ({
        inputs: [
            {
                label: "Email",
                type: "email",
                placeholder: "Email Address",
                input: "",
                icon: "mdi-email",
            },
            {
                label: "Password",
                type: "password",
                placeholder: "Password",
                input: "",
                icon: "mdi-lock",
            },
        ],
    }),
    name: "DashboardView",
    methods: {
        sayHi() {
            console.log("Hi!");
        },
        clickMe() {
            console.log(this.inputs[0].input);
            console.log(this.inputs[1].input);
        },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
